import {PortalHost, PortalProvider} from "conjointment"
import AppHistory from "shared/history.js"
import {createRoot} from "react-dom/client"
import {Container as FlashNotifications} from "flash-notifications"
import {LoadingBlocker} from "components/layout/loading-blocker"
import ModelsResponseReader from "@kaspernj/api-maker/build/models-response-reader"
import React from "react"
import Router from "@kaspernj/api-maker/build/router"
import RoutesPlay from "shared/routes-play"
import SessionStatusUpdater from "@kaspernj/api-maker/build/session-status-updater"
import WithLocationPath from "on-location-changed/build/with-location-path"

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const routeDefinitions = require("shared/route-definitions-play.json")

require("shared/devise")

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  globalThis.currentUser = models[0]
}

globalThis.sessionStatusUpdater = new SessionStatusUpdater()
globalThis.sessionStatusUpdater.startTimeout()

const requireComponent = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/play/${routeDefinition.component}-route`))

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <WithLocationPath>
      <PortalProvider>
        <PortalHost>
          <LoadingBlocker />
          <FlashNotifications />
          <Router
            history={AppHistory}
            notFoundComponent={LayoutNotFoundPage}
            requireComponent={requireComponent}
            routeDefinitions={routeDefinitions}
            routes={RoutesPlay}
          />
        </PortalHost>
      </PortalProvider>
    </WithLocationPath>
  )
})
